import React from 'react'
import Pic from '../images/Pic.jpg';

export const About = () => (
    <div className="container-fluid even" id="section1">
        <div className="container odd shadow btb-3 pb-4">
            <h1>Ab<span className="bg-red">ou</span>t me<span className="bg-red display-4">.</span></h1>
            <div className="row">
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-6 col-sm-6 col-md-5">
                      <div>
                        <img src={Pic} className="img-fluid rounded b-shadow-a" alt="Prashanth" />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-7 mobile-top-gap">
                      <div className="">
                        <p><span className="title-s">N<span className="bg-red">am</span>e: </span> <span className="font-weight-bold">Prashanth Karunakaran</span></p>
                        <p><span className="title-s">P<span className="bg-red">ro</span>file: </span> <span  className="font-weight-bold">UI/UX Hybrid Designer <span className="bg-red">&</span> Developer</span></p>
                        <p><span className="title-s">E<span className="bg-red">ma</span>il: </span> <span className="text-break font-weight-bold">prashanth.co.uk@gmail.com</span></p>
                      </div>
                    </div>
                  </div>
                    <p className="lead mt-3">
                        1<span className="bg-red">4+</span> years of professional experience on W<span className="bg-red">eb</span> and M<span className="bg-red">ob</span>ile U<span className="bg-red">I/U</span>X Design, Fr<span className="bg-red">on</span>t End W<span className="bg-red">eb</span> Develop<span className="bg-red">me</span>nt, Hy<span className="bg-red">br</span>id App, R<span className="bg-red">WD</span> (Responsive Web Design), Fl<span className="bg-red">as</span>h based CBT's including vi<span className="bg-red">de</span>o based training. Have hands on experience in Re<span className="bg-red">ac</span>t Js, Re<span className="bg-red">du</span>x, J<span className="bg-red">es</span>t and En<span className="bg-red">zy</span>me<span className="bg-red">.</span>
                    </p>
                    <p className="lead">                      
                        Pi<span className="bg-red">on</span>eered and implemented w<span className="bg-red">or</span>k on HTML5, C<span className="bg-red">SS</span>3 Media Queries, R<span className="bg-red">WD</span> (Responsive Web Design), Mob<span className="bg-red">il</span>e Websites <span className="bg-red">&</span> Hyb<span className="bg-red">ri</span>d approach for mob<span className="bg-red">il</span>e application, in 20<span className="bg-red">11</span>.
                    </p>
                    {/*<div className="skill-mf">
                    <p className="title-s">Skill</p>
                    <span>HTML</span> <span className="pull-right">85%</span>
                    <div className="progress">
                      <div className="progress-bar" role="progressbar" style={{width: "85%"}} aria-valuenow="85" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <span>CSS3</span> <span className="pull-right">75%</span>
                    <div className="progress">
                      <div className="progress-bar" role="progressbar" style={{width: "75%"}} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <span>PHP</span> <span className="pull-right">50%</span>
                    <div className="progress">
                      <div className="progress-bar" role="progressbar" style={{width: "50%"}} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <span>JAVASCRIPT</span> <span className="pull-right">90%</span>
                    <div className="progress">
                      <div className="progress-bar" role="progressbar" style={{width: "90%"}} aria-valuenow="90" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div> */}
                </div>
                <div className="col-md-6">
                    <p className="lead">                      
                        P<span className="bg-red">os</span>sess Experience of Work<span className="bg-red">in</span>g as a Mobile Applica<span className="bg-red">ti</span>on UX Des<span className="bg-red">ig</span>n Architect <span className="bg-red">&</span> Front end devel<span className="bg-red">op</span>er for more than a hund<span className="bg-red">re</span>d plus iPhone, iPad <span className="bg-red">&</span> Android applicat<span className="bg-red">io</span>ns which are l<span className="bg-red">iv</span>e on the app st<span className="bg-red">or</span>e. Also have solely h<span className="bg-red">an</span>dled and developed HT<span className="bg-red">ML</span>5 web app’s for iPh<span className="bg-red">on</span>e, iPad, Android <span className="bg-red">&</span> Flash Lite applicat<span className="bg-red">io</span>n for Symbian mobi<span className="bg-red">le</span>s.
                    </p>
                    <p className="lead">                          
                        Co-<span className="bg-red">fo</span>under of an innovative edu<span className="bg-red">ca</span>tional software for stud<span className="bg-red">en</span>ts appearing for the Com<span className="bg-red">mo</span>n Entrance Test called “C<span className="bg-red">ET</span> PAPERS”, Which was a Fl<span className="bg-red">as</span>h Based CBT helping s<span className="bg-red">tu</span>dents throughout In<span className="bg-red">di</span>a for their prepara<span className="bg-red">ti</span>on for any kind of competi<span className="bg-red">ti</span>ve exams. Also h<span className="bg-red">av</span>e designed <span className="bg-red">&</span> developed num<span className="bg-red">be</span>r of Websites as p<span className="bg-red">er</span> the client needs <span className="bg-red">&</span> have done S<span className="bg-red">EO</span> for most of t<span className="bg-red">he</span>m.
                    </p>
                </div>
              </div>
        </div>
    </div>
)

import React from 'react'
import Typical from 'react-typical'
import Particles from "react-tsparticles";

export const Header = () => {
    return (
        <header id="home" className="masthead">
            <div className="container">
                <div className="row">
                    <div className="col-lg-1"></div>
                    <div id="header-text" className="col-lg-10">
                        <div className="text-centre">
                            <h4><small>Hi there<span className="bg-red font-size">,</span> This is</small></h4>
                            <h1 className="font-size--large"><span>P<small><span className="bg-red">ra</span>shanth</small> K<small><span className="bg-red">ar</span>unaka<span className="bg-red">ra</span>n</small></span></h1>
                            <h3 className="pt-2"><span><small>I<span className="bg-red font-size">'</span>m a passionate {' '}</small> 
                                <Typical
                                    steps={[                                    
                                        'Web Designer', 
                                        2000,
                                        'UX Designer', 
                                        2000,
                                        'UI Developer', 
                                        2000,
                                        'Hybrid Mobile App', 
                                        2000,
                                        'React Developer', 
                                        2000
                                    ]}
                                    loop={Infinity}
                                    wrapper="b"
                                />
                                </span>
                            </h3>
                            <ul className="pt-5 social-network social-circle list-inline">
                                <li className="list-inline-item"><a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/prashanth.karunakaran/" className="icoFacebook" title="Facebook"><i className="fa fa-facebook"></i></a></li>
                                <li className="list-inline-item"><a target="_blank" rel="noopener noreferrer" href="https://twitter.com/Elminate" className="icoTwitter" title="Twitter"><i className="fa fa-twitter"></i></a></li>
                                <li className="list-inline-item"><a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=hNa4Xyxy6KY&t=34s" className="icoYoutube" title="Youtube"><i className="fa fa-youtube-play"></i></a></li>
                                <li className="list-inline-item"><a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/prashanthkarunakaran/" className="icoLinkedin" title="Linkedin"><i className="fa fa-linkedin"></i></a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-1"></div>
                </div>
            </div>
            <Particles
              height={window.innerHeight}
              id="tsparticles"
                options={{
                  fpsLimit: 60,
                  interactivity: {
                    detectsOn: "canvas",
                    events: {
                      onClick: {
                        enable: true,
                        mode: "push",
                      },
                      onHover: {
                        enable: true,
                        mode: "repulse",
                      },
                      resize: true,
                    },
                    modes: {
                      bubble: {
                        distance: 400,
                        duration: 2,
                        opacity: 0.1,
                        size: 40,
                      },
                      push: {
                        quantity: 4,
                      },
                      repulse: {
                        distance: 200,
                        duration: 0.4,
                      },
                    },
                  },
                  particles: {
                    color: {
                      value: "#000",
                    },
                    links: {
                      color: "#000",
                      distance: 150,
                      enable: true,
                      opacity: 0.1,
                      width: 1,
                    },
                    collisions: {
                      enable: true,
                    },
                    move: {
                      direction: "none",
                      enable: true,
                      outMode: "bounce",
                      random: false,
                      speed: 6,
                      straight: false,
                    },
                    number: {
                      density: {
                        enable: true,
                        value_area: 800,
                      },
                      value: 80,
                    },
                    opacity: {
                      value: 0.08,
                    },
                    shape: {
                      type: "circle",
                    },
                    size: {
                      random: true,
                      value: 5,
                    },
                  },
                  detectRetina: true,
                }}
            />
        </header>
    )
}

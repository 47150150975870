
import React, { useState } from 'react'
import { Axios, db } from '../firebase/firebaseConfig'

export const ContactForm = () => {
  const [formData, setFormData] = useState({})

  const updateInput = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    })
  }
  const handleSubmit = event => {
    event.preventDefault()
    sendEmail()
    setFormData({
      name: '',
      email: '',
      subject: '',
      message: '',
    })
  }
  const sendEmail = () => {
    Axios.post(
      'https://us-central1-email-65648.cloudfunctions.net/submit',
      formData
    )
      .then(res => {
        db.collection('emails').add({
          name: formData.name,
          email: formData.email,
          subject: formData.subject,
          message: formData.message,
          time: new Date(),
        })
      })
      .catch(error => {
        console.log(error)
      })
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
          <div className="form-group">
            <input
                className="form-control"
                type="text"
                name="name"
                placeholder="Name"
                onChange={updateInput}
                value={formData.name || ''}
            />
          </div>
          <div className="form-group">
                <input
                    className="form-control"
                    type="email"
                    name="email"
                    placeholder="Email"
                    onChange={updateInput}
                    value={formData.email || ''}
                />
            </div>
            <div className="form-group">
                <input
                    className="form-control"
                    type="text"
                    name="subject"
                    placeholder="Subject"
                    onChange={updateInput}
                    value={formData.subject || ''}
                />
            </div>
            <div className="form-group">
                <textarea
                    className="form-control"
                    type="text"
                    name="message"
                    placeholder="Message"
                    rows="3"
                    onChange={updateInput}
                    value={formData.message || ''}
                ></textarea>
            </div>
            <div className="send">
                <button 
                    className="btn btn-primary">
                        {/* <i className="fa fa-envelope-o" 
                            aria-hidden="true" 
                        /> */} Send
                </button>
            </div>
      </form>
    </>
  )
};
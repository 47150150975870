import { Map, GoogleApiWrapper, Marker, InfoWindow } from "google-maps-react";
import React, { Component } from "react";

const style = {
  width: "auto",
  height: "300px"
};

class MapContainer extends Component {
  render() {
    return (
      <Map
        google={this.props.google}
        /* style={style} */
        initialCenter={{
          lat: this.props.lat,
          lng: this.props.lng,
        }}
        zoom={14}
        onClick={this.onMapClicked}
      ><Marker key="marker_1"
      position={{
      lat: this.props.lat,
      lng: this.props.lng
      }}
      /></Map>
    );
  }
}

const LoadingContainer = (props) => <div>Fancy loading container!</div>;

const GoogleMap = GoogleApiWrapper({
  apiKey: 'AIzaSyCvPtJ8boUbzCEaVo2Ki-U-23oat1Phe30',
  LoadingContainer: LoadingContainer,
})(MapContainer);

export { GoogleMap };
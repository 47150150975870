import React from 'react'

export const Footer = () => (
    <div className="container-fluid bg-black">
        <footer id="footer">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 text-white">
                        <div className="copyright">
                            © Copyright <strong className="bg-red">Prashanth Karunakaran</strong>. All Rights Reserved
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </div>
)

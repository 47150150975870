import React from 'react'
import { GoogleMap, ContactForm } from '../components';

export const Contact = () => (
    <div className="container-fluid" id="section4">
        <div className="container shadow btb-3 bg-white">
            <h1>Get in to<span className="bg-red">uc</span>h<span className="bg-red display-4">.</span></h1>
            <p className="lead mt-3">I sp<span className="bg-red">ec</span>ialize in creating stylish modern w<span className="bg-red">eb</span>sites. My p<span className="bg-red">as</span>sion is to design digital user e<span className="bg-red">xp</span>erience through simple in<span className="bg-red">tu</span>itive user-friendly interf<span className="bg-red">ac</span>e.</p>           
            <p className="lead mt-3">Interested in w<span className="bg-red">or</span>king together? Please let me k<span className="bg-red">no</span>w. I am based out of N<span className="bg-red">or</span>wich.</p>
            <div className="row pb-5">
                <div className="col-md-6 google-maps">
                    <GoogleMap lat="52.631265" lng="1.297570" /> 
                </div>
                <div className="col-md-6">
                  <div className="contact-form">
                    {/* <h4>Say Something</h4> */}
                    <h4>Drop Me A L<span className="bg-red">in</span>e<span className="bg-red fs-2">.</span></h4>
                    <ContactForm />
                  </div>    
                </div>                
              </div>
        </div>
    </div>
)

import React, { Component } from "react";
import Chart from "react-apexcharts";

export class SkillsCharts extends Component {

    state = {
        options: {
            chart: {
                id: "basic-bar",
                width: '100%',
                background: '#fff',
            },
            fill: {                
                colors: '#1976d2',
                opacity: 1,
                type: 'solid',                
            },
            xaxis: {
                categories: ["HTML5", "CSS3", "Javascript", "Jquery", "ReactJs", "Photoshop", "Illustrator", "Mobile-Hybrid-App", "UX-Skills"]
            },
            yaxis: {
                labels: {
                    style: {
                        fontSize: '14px',
                        fontWeight: 'bold',
                    }
                },
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                    startingShape: 'flat',
                    endingShape: 'flat',
                    columnWidth: '70%',
                    barHeight: '70%',
                    distributed: false,
                    rangeBarOverlap: true,
                    colors: {
                        ranges: [{
                            from: 0,
                            to: 1,
                            color: '#000000'
                        }],
                        backgroundBarColors: [],
                        backgroundBarOpacity: 1,
                        backgroundBarRadius: 0,
                    },
                    dataLabels: {
                        position: 'top',
                        maxItems: 100,
                        hideOverflowingLabels: true,
                        orientation: 'horizontal'
                    },
                    fill: {
                        type: 'solid',
                        colors: ['#000000']
                    },
                }
            },
            responsive: [
                {
                  breakpoint: 768,
                  options: {
                    chart: {
                        height: 500,
                    },
                    plotOptions: {
                      bar: {
                        horizontal: false
                      }
                    },
                    legend: {
                      position: "bottom"
                    }
                  }
                }
            ]
        },
        series: [
            {
                name: "Level of expertise",
                data: [8, 9, 9, 8, 6.5, 8, 7, 8, 6.5 ]
            }
        ],
    };

    render() {
        return (
                <Chart
                    options={this.state.options}
                    series={this.state.series}
                    type="bar"
                />
        );
    }
};
import React from 'react'
import { SkillsCharts } from '../components';

export const Skills = () => (
    <div className="container-fluid" id="section2">
        <div className="container shadow btb-3 bg-white">
            <h1>Ski<span className="bg-red">ll</span>s<span className="bg-red display-4">.</span></h1>
            <SkillsCharts />
        </div>
    </div>
)

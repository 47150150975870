import React, { useState } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
} from 'reactstrap';
import { 
  Link, 
  animateScroll as scroll
} from "react-scroll";
import logo from '../images/Logo.png'; 

export const CustomNavBar = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <>
      <Navbar expand="md" className="fixed-top navbar-color">
        <NavbarBrand href="/"><img src={logo} alt="Logo" /></NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
        <div className="container justify-content-center">
          <Nav navbar>
          <NavItem>
              <Link
                activeClass="active"
                className="nav-link"
                to="home"
                spy={true}
                smooth={true}
                offset={-70}
                duration={1000}
              >
                Home
              </Link>
            </NavItem>
            <NavItem>
              <Link
                activeClass="active"
                className="nav-link"
                to="section1"
                spy={true}
                smooth={true}
                offset={-70}
                duration={1000}
              >
                About
              </Link>
            </NavItem>
            <NavItem>
              <Link
                activeClass="active"
                className="nav-link"
                to="section2"
                spy={true}
                smooth={true}
                offset={-70}
                duration={1000}
              >
                Skills
              </Link>
            </NavItem>
            <NavItem>
              <Link
                activeClass="active"
                className="nav-link"
                to="section3"
                spy={true}
                smooth={true}
                offset={-70}
                duration={1000}
              >
                News
              </Link>
            </NavItem>
            <NavItem>
              <Link
                activeClass="active"
                className="nav-link"
                to="section4"
                spy={true}
                smooth={true}
                offset={-70}
                duration={1000}
              >
                Contact
              </Link>
            </NavItem>
          </Nav>
          </div>
        </Collapse>
      </Navbar>
    </>
  );
}
import React from 'react'
import ReactPlayer from 'react-player'

export const News = () => (
    <div className="container-fluid even" id="section3">
        <div className="container odd p-4 shadow btb-3">
            <h1 className="margin-top--fix">N<span className="bg-red">ew</span>s<span className="bg-red display-4">.</span></h1>
            <p className="lead mt-3">”CET PAP<span className="bg-red">ER</span>S” product along with my int<span className="bg-red">er</span>view was covered on all major lo<span className="bg-red">ca</span>l news channels like Ud<span className="bg-red">ay</span>a news, TV9 N<span className="bg-red">ew</span>s, Suvarna news <span className="bg-red">&</span> leading n<span className="bg-red">ew</span>s papers like DNA, Sanjevani <span className="bg-red">&</span> 9 other news pap<span className="bg-red">er</span>s. A glimpse of the s<span className="bg-red">am</span>e.</p>
            <div className="bg-black player-wrapper">
                <ReactPlayer 
                    className="react-player"
                    width="100%"
                    controls
                    url='https://youtu.be/hNa4Xyxy6KY' 
                />
            </div>
        </div>
    </div>
)

import React from 'react';
import CookieConsent from "react-cookie-consent";
import { 
  CustomNavBar,
  Header
} from './components';
import { 
  About, 
  Contact, 
  Footer, 
  News,
  Skills
} from './pages';

function App() {
  return (
    <>
      <CookieConsent
        location="bottom"
        buttonText="Got it!"
        cookieName="prashanth.co.uk"
        style={{ background: "#000", opacity:"0.7" }}
        buttonStyle={{ background: "#007bff", color: "#fff",fontSize: "1.2em"}}
        expires={150}
      ><h5>Cookies and You</h5>
      We use cookies on this website. You are free to manage these via your browser settings at any time. For more about how we use cookies, please see our <a href="http://www.prashanth.co.uk">Privacy Policy</a>.
      </CookieConsent>
      <CustomNavBar />
      <Header />
      <About />    
      <Skills />
      <News />
      <Contact />
      <Footer />
    </>
  );
}

export default App;
